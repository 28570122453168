import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import Styles from './Media.module.scss'
import {Image} from 'react-bootstrap';
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import CommonHelper from "../../helper/CommonHelper";
import {navigate} from "../../helper/HistoryHelper";
import {Img} from "react-image";
import moment from "moment";
import {MdThumbDown, MdThumbUp} from "react-icons/md";
import Thumbnail from "./Thumbnail";
@observer
class Media extends Component {

    constructor(props) {
        super(props);
        let {file} = props;
        let src = []
        if(file.thumbnailPath) {
            src.push(`${THUMBNAIL_BASE_URL}/${file?.folder?.id}/${file?.id}/${file.thumbnailPath}`);
        }else if(!!file.isCreatedSpriteThumbnail){
            let number = parseInt(Math.floor(Math.random() * file.duration) / 5) + 2;
            src.push(`${THUMBNAIL_BASE_URL}/${file?.folder?.id}/${file?.id}/sprite/${String(number).padStart(10,"0")}.jpg`);
        }
        src.push(`/asset/img/no_thumbnail.png`);
        this.state = {
            enter: false,
            src: src||[]
        }
    }

    render() {
        let {file, onClick, dateLabel = `${moment(file?.created).fromNow()} 등록됨`} = this.props;
        let {duration, history} = file;
        let playedSeconds = history?.playedSeconds;

        return (
            <div className={Styles.Media}>
                <div className={Styles.Thumbnail}
                     onClick={()=>{
                         onClick && onClick(file)
                     }}
                >
                    <Thumbnail enter={this.state.enter} file={file} src={this.state.src}/>
                    <div className={Styles.Cover}
                         onMouseEnter={e=>{
                             this.setState({enter: true})
                         }}
                         onMouseLeave={e=>{
                             this.setState({enter: false})
                         }}
                    >
                        <div>

                        </div>
                        <div>

                        </div>
                        <div className={Styles.Bottom}>
                            <div className={Styles.Info}>
                                {
                                    file?.likeCount > 0 &&
                                        <div className={Styles.Like}
                                             style={{color:file?.isLike ? 'var(--bs-info)' : '#fff'}}
                                        >
                                            <MdThumbUp/>&nbsp;{file?.likeCount?.toLocaleString()}
                                        </div>
                                }
                                {
                                    appStore.isAdmin && file?.hateCount > 0 &&
                                    <div className={Styles.Hate}
                                         style={{color: file?.isHate ? 'var(--bs-danger)' : '#fff'}}
                                    >
                                        <MdThumbDown/>&nbsp;{file?.hateCount?.toLocaleString()}
                                    </div>
                                }
                                <div></div>
                                <div className={Styles.Duration}>
                                    {CommonHelper.millisecondConvertTime(duration)}
                                </div>
                            </div>
                            <div>
                                {
                                    playedSeconds != undefined &&
                                        <div className={Styles.Progress}>
                                            <div className={Styles.Ing} style={{width: `${playedSeconds / duration * 100}%`}}></div>
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className={Styles.Title}>
                    {file.name}
                </div>
                <div className={Styles.Info}>
                    조회수 {file?.viewCount?.toLocaleString()}회 ∙ {dateLabel}
                </div>
            </div>
        );
    }
}

Media.propTypes = {};

export default Media;