import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {Img} from "react-image";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";

@observer
class Thumbnail extends Component {

    interval;

    constructor(props) {
        super(props);
        let {file} = props;
        let number = parseInt(Math.floor(Math.random() * file.duration) / 5) + 2;
        let src = `${THUMBNAIL_BASE_URL}/${file?.folder?.id}/${file?.id}/sprite/${String(number).padStart(10,"0")}.jpg`;
        this.state = {
            src
        }

    }

    componentDidMount() {
        let {file} = this.props;
        this.interval = setInterval(()=>{
                let number = parseInt(Math.floor(Math.random() * file.duration) / 5) + 2;
                let src = `${THUMBNAIL_BASE_URL}/${file?.folder?.id}/${file?.id}/sprite/${String(number).padStart(10,"0")}.jpg`;
                this.setState({src})
            }
            ,1000
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        let {src, enter, file} = this.props
        return (
            <>
            {
                enter && !!file.isCreatedSpriteThumbnail ?
                    <Img src={this.state.src}/>
                    :
                    <Img src={src}/>
            }
            </>
        );
    }
}

export default Thumbnail;